import React from 'react';
import PrivacyGenEsp from '../components/PrivacyGenEsp';
import Layout from '../templates/Layout';
import SEO from '../components/SEO';

const PrivacyPageEsp = () => {
  return (
    <Layout>
      <SEO pageTitle="Política de Privacidad" />
      <PrivacyGenEsp />
    </Layout>
  );
};

export default PrivacyPageEsp;
